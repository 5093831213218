import React, { useState } from "react";
import { TablePagination } from "@mui/material";
import { ChevronRight, ChevronDown } from "lucide-react";

// Simple Badge Component
const Badge = ({ children, variant }) => {
  const getVariantStyles = () => {
    switch (variant) {
      case "success":
        return "bg-green-100 text-green-800";
      case "destructive":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return (
    <span
      className={`px-2 py-1 text-xs font-medium rounded-full ${getVariantStyles()}`}
    >
      {children}
    </span>
  );
};

export default function Table({ columns = [], rows = [] }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [expandedCards, setExpandedCards] = useState({});

  // Calculate pagination
  const indexOfLastRow = (page + 1) * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentItems = rows.slice(indexOfFirstRow, indexOfLastRow);
  const count = rows.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleCardExpansion = (index) => {
    setExpandedCards((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Format cell content based on type
  const formatCellContent = (content) => {
    if (typeof content === "boolean") {
      return (
        <Badge variant={content ? "success" : "destructive"}>
          {content ? "Yes" : "No"}
        </Badge>
      );
    }
    return content;
  };

  // If no data, show empty state
  if (!rows.length) {
    return (
      <div className="w-full p-8 text-center">
        <p className="text-gray-500">No data available</p>
      </div>
    );
  }

  return (
    <div className="w-full">
      {/* Custom Scrollbar Styles */}
      <style>
        {`
          .custom-scrollbar::-webkit-scrollbar {
            width: 9px;
            height: 9px;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: rgba(155, 155, 155, 0.7);
            border-radius: 10px;
          }
          .custom-scrollbar::-webkit-scrollbar-track {
            background-color: rgba(155, 155, 155, 0.1);
          }
        `}
      </style>

      {/* Mobile View */}
      <div className="block md:hidden space-y-4">
        {currentItems.map((row, rowIndex) => (
          <div
            key={rowIndex}
            className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-200 hover:shadow-lg"
          >
            {/* Card Header - Always visible */}
            <div
              className="p-2 bg-gray-50 flex justify-between items-center cursor-pointer w-full"
              onClick={() => toggleCardExpansion(rowIndex)}
            >
              <div className=" w-full flex-1">
                <h3 className=" text-gray-900">
                  {columns[0]?.cell?.(row, rowIndex) || "N/A"}
                </h3>
                <div className="flex items-center">
                    <span>Expire Date:</span>
                  <p className="flex text-sm text-gray-500">
                    {columns[5]?.cell?.(row, rowIndex) || ""}
                  </p>
                </div>
              </div>
              {expandedCards[rowIndex] ? (
                <ChevronDown className="h-5 w-5 text-gray-400" />
              ) : (
                <ChevronRight className="h-5 w-5 text-gray-400" />
              )}
            </div>

            {/* Card Details - Expandable */}
            <div
              className={`transition-all duration-200 ${
                expandedCards[rowIndex]
                  ? "max-h-[500px] opacity-100"
                  : "max-h-0 opacity-0"
              } overflow-hidden`}
            >
              <div className="p-2 space-y-1 border-t border-gray-100">
                {columns.slice(2).map((column, colIndex) => (
                  <div
                    key={colIndex}
                    className="flex justify-between items-center text-sm"
                  >
                    <span className="text-gray-600 font-medium">
                      {column.name}
                    </span>
                    <span className="text-gray-900">
                      {formatCellContent(column.cell?.(row, rowIndex) || "N/A")}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Desktop View */}
      <div className="hidden md:block border border-gray-200 rounded-lg overflow-hidden bg-white">
        <div className="overflow-x-auto custom-scrollbar">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-200">
                {columns.map((column, index) => (
                  <th
                    key={index}
                    className="px-6 py-1 text-left text-xs font-semibold text-gray-700 border-b border-gray-200"
                  >
                    {column.name || ""}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {currentItems.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="hover:bg-gray-50 transition-colors duration-150"
                >
                  {columns.map((column, colIndex) => (
                    <td
                      key={colIndex}
                      className="mx-auto px-6 py-2 text-sm text-gray-900 "
                    >
                      {formatCellContent(column.cell?.(row, rowIndex) || "N/A")}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination */}
      <div className=" bg-white rounded-lg">
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 20, 30, 50]}
        />
      </div>
    </div>
  );
}
