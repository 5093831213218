import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Client from "./pages/Client";
import Layout from "./layouts/Layout";
import Login from "./pages/Login";
import LoginLayout from "./layouts/LoginLayout";
import DomainEntry from "./pages/DomainEntry";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="client-management" element={<Client />} />
          <Route path="domain-entry" element={<DomainEntry />} />
        </Route>
        <Route path="/" element={<LoginLayout />}>
          <Route path="login" element={<Login />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
