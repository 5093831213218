import { useContext, useEffect, useRef, useState } from "react";
import BookingTabNavigation from "../components/BookingTabNavigation";
import { Modal } from "antd";
import { message } from "antd";
import ClientApis from "../services/api/clientApi";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Client() {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [mailModal, setMailModal] = useState(false);

  const [providedDomainBy, setProvidedDomainBy] = useState("");
  const [domainRegistrar, setDomainRegistrar] = useState("");
  const [providedHostingBy, setProvidedHostingBy] = useState("");
  const [hostingRegistrar, setHostingRegistrar] = useState("");

  const [bookingId, setBookingId] = useState("");
  const [firmName, setFirmName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("Completed");
  const [domainName, setDomainName] = useState("");
  const [hostingName, setHostingName] = useState("");
  const [expiryDomainDate, setExpiryDomainDate] = useState(null);
  const [expiryHostingDate, setExpiryHostingDate] = useState(null);

  const [activeKey, setActiveKey] = useState(1);
  const [clientList, setClientList] = useState([]);

  const [searchDomainBy, setSearchDomainBy] = useState("");
  const [searchHostingBy, setSearchHostingBy] = useState("");
  const [search, setSearch] = useState("");

  const [clientId, setClientId] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [emailForMail, setEmailForMail] = useState("");
  const [domainNameForMail, setDomainNameForMail] = useState("");
  const [totalCostFroMail, setTotalCostFroMail] = useState("");
  const [dateFormatFroMail, setDateFormatFroMail] = useState("");
  const [paymentLinkFroMail, setPaymentLinkFroMail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const location = useLocation();
  const { id } = location.state || {}; // Access the id from location.state

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setActiveKey(id);
    } else {
      setActiveKey(1);
    }
  }, [id]);

  useEffect(() => {
    const idDrm = localStorage.getItem("idDrm");
    if (idDrm == "2") {
      navigate("/domain-entry");
    }
  }, []);

  const handleSearch = async () => {
    const response = await ClientApis.getDateBySearchQuery(searchQuery);
    if (response) {
      setSearchResults(response);
    }
  };

  const handleProvidedByDomainChange = (e) => {
    setProvidedDomainBy(e.target.value);
  };

  const handleRegistrarDomainChange = (e) => {
    setDomainRegistrar(e.target.value);
  };

  const handleProvidedByHostingChange = (e) => {
    setProvidedHostingBy(e.target.value);
  };

  const handleRegistrarHostingChange = (e) => {
    setHostingRegistrar(e.target.value);
  };

  const fetchClientList = async () => {
    const params = {
      searchDomainBy,
      searchHostingBy,
      search,
      activeKey,
    };
    const response = await ClientApis.findAll({ params });
    if (response) {
      setClientList(response);
    }
  };

  const handleAddClient = async () => {
    if (!firmName) {
      console.error("Error: firmName is required.");
      message.error("Firm Name is required.");
      return;
    }
    if (!phone || phone.length !== 10 || isNaN(phone)) {
      console.error("Error: phone number is required.");
      message.error("Phone number is required and should be valid.");
      return;
    }
    if (!email || !email.includes("@" && ".")) {
      console.error("Error: email is required.");
      message.error("Email is required and should be valid.");
      return;
    }
    if (!paymentStatus) {
      console.error("Error: payment status is required.");
      message.error("Payment status is required.");
      return;
    }
    if (!domainName) {
      console.error("Error: domain name is required.");
      message.error("Domain name is required.");
      return;
    }
    if (!providedDomainBy) {
      console.error("Error: domain provided by is required.");
      message.error("Domain provided by is required.");
      return;
    }
    if (providedDomainBy === "egniolDomain" && !domainRegistrar) {
      console.error("Error: domain registrar is required.");
      message.error("Domain registrar is required.");
      return;
    }
    if (providedDomainBy === "egniolDomain" && !expiryDomainDate) {
      console.error("Error: domain expiry date is required.");
      message.error("Domain expiry date is required.");
      return;
    }
    if (!hostingName) {
      console.error("Error: hosting name is required.");
      message.error("Hosting name is required.");
      return;
    }
    if (!providedHostingBy) {
      console.error("Error: hosting provided by is required.");
      message.error("Hosting provided by is required.");
      return;
    }
    if (!hostingRegistrar && providedHostingBy === "egniolHosting") {
      console.error("Error: hosting registrar is required.");
      message.error("Hosting registrar is required.");
      return;
    }
    if (!expiryHostingDate && providedHostingBy === "egniolHosting") {
      console.error("Error: hosting expiry date is required.");
      message.error("Hosting expiry date is required.");
      return;
    }

    const body = {
      bookingId,
      firmName,
      phoneNumber: phone,
      email,
      paymentStatus,
      domainName,
      domainRegistrar,
      expiryDomainDate,
      hostingName,
      hostingRegistrar,
      expiryHostingDate,
      providedDomainBy,
      providedHostingBy,
    };
    const response = await ClientApis.create(body);
    if (response) {
      setIsOpen(false);
      setIsEditOpen(false);
      setIsViewOpen(false);
      setBookingId("");
      setFirmName("");
      setPhone("");
      setEmail("");
      setPaymentStatus("Completed");
      setDomainName("");
      setProvidedDomainBy("");
      setDomainRegistrar("");
      setExpiryDomainDate(null);
      setHostingName("");
      setProvidedHostingBy("");
      setHostingRegistrar("");
      setExpiryHostingDate(null);
      setClientId("");
    }
    fetchClientList();
  };

  useEffect(() => {
    fetchClientList();
  }, [activeKey, searchDomainBy, searchHostingBy, search]);

  const onChangeDomainName = (e) => {
    setDomainName(e.target.value);
    setHostingName(e.target.value);
  };

  useEffect(() => {
    if (clientId) {
      const fetchClient = async () => {
        const response = await ClientApis.findOne(clientId);
        if (response) {
          setBookingId(response.bookingId);
          setFirmName(response.firmName);
          setPhone(response.phoneNumber);
          setEmail(response.email);
          setPaymentStatus(response.paymentStatus);
          setDomainName(response.domainName);
          setProvidedDomainBy(response.providedDomainBy);
          setDomainRegistrar(response.domainRegistrar);
          setExpiryDomainDate(response.expiryDomainDate);
          setHostingName(response.hostingName);
          setProvidedHostingBy(response.providedHostingBy);
          setHostingRegistrar(response.hostingRegistrar);
          setExpiryHostingDate(response.expiryHostingDate);
        }
      };
      fetchClient();
    }
  }, [clientId]);

  const handleUpdateClient = async () => {
    if (!firmName) {
      console.error("Error: firmName is required.");
      message.error("Firm Name is required.");
      return;
    }
    if (!phone) {
      console.error("Error: phone number is required.");
      message.error("Phone number is required.");
      return;
    }
    if (!email) {
      console.error("Error: email is required.");
      message.error("Email is required.");
      return;
    }
    if (!paymentStatus) {
      console.error("Error: payment status is required.");
      message.error("Payment status is required.");
      return;
    }
    if (!domainName) {
      console.error("Error: domain name is required.");
      message.error("Domain name is required.");
      return;
    }
    if (!providedDomainBy) {
      console.error("Error: domain provided by is required.");
      message.error("Domain provided by is required.");
      return;
    }
    if (providedDomainBy === "egniolDomain" && !domainRegistrar) {
      console.error("Error: domain registrar is required.");
      message.error("Domain registrar is required.");
      return;
    }
    if (providedDomainBy === "egniolDomain" && !expiryDomainDate) {
      console.error("Error: domain expiry date is required.");
      message.error("Domain expiry date is required.");
      return;
    }
    if (!hostingName) {
      console.error("Error: hosting name is required.");
      message.error("Hosting name is required.");
      return;
    }
    if (!providedHostingBy) {
      console.error("Error: hosting provided by is required.");
      message.error("Hosting provided by is required.");
      return;
    }
    if (!hostingRegistrar && providedHostingBy === "egniolHosting") {
      console.error("Error: hosting registrar is required.");
      message.error("Hosting registrar is required.");
      return;
    }
    if (!expiryHostingDate && providedHostingBy === "egniolHosting") {
      console.error("Error: hosting expiry date is required.");
      message.error("Hosting expiry date is required.");
      return;
    }

    const body = {
      bookingId,
      firmName,
      phoneNumber: phone,
      email,
      paymentStatus,
      domainName,
      domainRegistrar,
      expiryDomainDate,
      hostingName,
      hostingRegistrar,
      expiryHostingDate,
      providedDomainBy,
      providedHostingBy,
    };
    const response = await ClientApis.update(clientId, body);
    if (response) {
      setIsEditOpen(false);
      setIsViewOpen(false);
      setBookingId("");
      setFirmName("");
      setPhone("");
      setEmail("");
      setPaymentStatus("Completed");
      setDomainName("");
      setProvidedDomainBy("");
      setDomainRegistrar("");
      setExpiryDomainDate(null);
      setHostingName("");
      setProvidedHostingBy("");
      setHostingRegistrar("");
      setExpiryHostingDate(null);
      setClientId("");
    }
    fetchClientList();
    setIsOpen(false);
  };

  const handleSendEmail = async (row) => {
    setMailModal(true);
    console.log("Email sent successfully", row);
    setDomainNameForMail(row.domainName);
    setDateFormatFroMail(row.expiryHostingDate);
    setEmailForMail(row.email);
  };
  const handleSendWhatsApp = (row) => {
    setMailModal(true);
    setDomainNameForMail(row.domainName);
    setDateFormatFroMail(row.expiryHostingDate);
    setEmailForMail(row.email);
    setPhoneNumber(row.phoneNumber);
  };

  const sendEmail = async () => {
    const data = {
      email: emailForMail,
      domainName: domainNameForMail,
      totalCost: totalCostFroMail,
      expiryDate: dateFormatFroMail,
      paymentLink: paymentLinkFroMail,
    };

    setMailModal(false);
    const response = await ClientApis.sendMail(data);
    console.log("response", response);

    if (response) {
      setEmailForMail("");
      setDomainNameForMail("");
      setTotalCostFroMail("");
      setDateFormatFroMail("");
      setPaymentLinkFroMail("");
    }
  };

  const sendWhatsAppMessage = async () => {
    const domainName = domainNameForMail;
    const expiryDate = dateFormatFroMail;
    const paymentLink = paymentLinkFroMail;
    const totalCost = totalCostFroMail;

    // Shortened WhatsApp message
    const message = `Dear Sir/Madam,
  
This is a reminder that the services for your website *${domainName}* are due for renewal.
  
  *Details:*
  - *Domain Name:* ${domainName}
  - *Hosting Plan:* 1 Year Platinum
  - *SSL Certificate:* 1 Year Secured SSL
  - *Backup:* Weekly for 1 Year
  - *Total Cost:* ₹${totalCost} + GST
  
  *Expiration Date:* ${expiryDate}
  
Please complete the renewal payment here: ${paymentLink}.
  
Note: Late renewal (after 7 days) incurs a ₹7000 + GST fee.
  
If you need any help, feel free to reach out. Thank you for choosing us!
  
Warm Regards,
Vatsal Solanki | Head Web Developer`;

    // Encode the message and open WhatsApp
    const clientNumber = phoneNumber;
    const whatsappUrl = `https://wa.me/+91${clientNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");

    setMailModal(false);
    setEmailForMail("");
    setDomainNameForMail("");
    setTotalCostFroMail("");
    setDateFormatFroMail("");
    setPaymentLinkFroMail("");
  };

  const handleSelectClient = async (client) => {
    setBookingId(client.bookingId);
    setFirmName(client.firmName);
    setPhone(client.phoneNumber);
    setEmail(client.email);
    setSearchModal(false);
    setIsOpen(true);
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="flex justify-between items-center w-full px-4">
                <h1>Client List</h1>
                <button
                  className="btn btn-primary"
                  onClick={() => setSearchModal(true)}
                >
                  Add Client
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="px-3">
          <BookingTabNavigation
            setIsOpen={setIsOpen}
            setIsEditOpen={setIsEditOpen}
            setIsViewOpen={setIsViewOpen}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            clientList={clientList}
            setSearchDomainBy={setSearchDomainBy}
            searchDomainBy={searchDomainBy}
            searchHostingBy={searchHostingBy}
            search={search}
            setSearchHostingBy={setSearchHostingBy}
            setSearch={setSearch}
            setClientId={setClientId}
            handleSendEmail={handleSendEmail}
            handleSendWhatsApp={handleSendWhatsApp}
          />
        </section>
        <Modal
          title={
            isEditOpen
              ? "Edit Client"
              : isViewOpen
              ? "View Client"
              : "Add Client"
          }
          open={isOpen}
          width={"700px"}
          onCancel={() => {
            setIsOpen(false);
            setIsEditOpen(false);
            setIsViewOpen(false);
            setBookingId("");
            setFirmName("");
            setPhone("");
            setEmail("");
            setPaymentStatus("Completed");
            setDomainName("");
            setProvidedDomainBy("");
            setDomainRegistrar("");
            setExpiryDomainDate(null);
            setHostingName("");
            setProvidedHostingBy("");
            setHostingRegistrar("");
            setExpiryHostingDate(null);
            setClientId("");
          }}
          footer={null}
          className="my-10"
          centered
        >
          <div className="flex h-[400px] overflow-y-auto md:h-auto flex-col md:flex-row justify-between md:py-6">
            <div className="pr-4 flex flex-col gap-2 mt-4 md:mt-0">
              <div className="flex gap-3">
                <div className="form-group w-1/2">
                  <label htmlFor="userId">Booking Id</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userId"
                    placeholder="Enter booking id"
                    value={bookingId}
                    onChange={(e) => setBookingId(e.target.value)}
                    disabled={isViewOpen}
                  />
                </div>
                {/* <button
                  className="btn btn-primary h-10 mt-7"
                  // onClick={() => getDataByBookingId(bookingId)}
                >
                  Search
                </button> */}
              </div>
              <div className="flex gap-3">
                <div className="form-group w-1/2">
                  <label htmlFor="userId">Firm Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userId"
                    placeholder="Firm Name"
                    value={firmName}
                    onChange={(e) => setFirmName(e.target.value)}
                    disabled={isViewOpen}
                  />
                </div>
                <div className="form-group w-1/2">
                  <label htmlFor="userId">Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="userId"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    disabled={isViewOpen}
                    maxLength={10}
                  />
                </div>
                <div className="form-group w-1/2">
                  <label htmlFor="userId">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="userId"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isViewOpen}
                  />
                </div>
                <div className="form-group w-1/2">
                  <label htmlFor="paymentStatus">Payment Status</label>
                  <select
                    className="form-control"
                    id="paymentStatus"
                    value={paymentStatus}
                    onChange={(e) => setPaymentStatus(e.target.value)}
                    disabled={isViewOpen}
                  >
                    <option value="" disabled>
                      Select Payment Status
                    </option>
                    <option value="Completed">Completed</option>
                    <option value="Pending">Pending</option>
                  </select>
                </div>
              </div>

              <div className="flex items-center gap-3">
                <div className="form-group w-1/2">
                  <label htmlFor="domainName">Domain Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="domainName"
                    placeholder="Enter domain name"
                    value={domainName}
                    onChange={onChangeDomainName}
                    disabled={isViewOpen}
                  />
                </div>

                <div className="form-group w-1/2 flex flex-col pl-1">
                  <label htmlFor="userId">Provided By</label>
                  <div className="flex gap-4 items-center">
                    <label
                      htmlFor="egniolDomain"
                      className="flex items-center gap-1"
                    >
                      <input
                        type="radio"
                        name="providedDomainBy"
                        id="egniolDomain"
                        value="egniolDomain"
                        onChange={handleProvidedByDomainChange}
                        checked={providedDomainBy === "egniolDomain"}
                        disabled={isViewOpen}
                      />
                      Egniol
                    </label>
                    <label
                      htmlFor="clientDomain"
                      className="flex items-center gap-1"
                    >
                      <input
                        type="radio"
                        name="providedDomainBy"
                        id="clientDomain"
                        value="clientDomain"
                        onChange={handleProvidedByDomainChange}
                        checked={providedDomainBy === "clientDomain"}
                        disabled={isViewOpen}
                      />
                      Client
                    </label>
                  </div>
                </div>
              </div>
              {providedDomainBy === "egniolDomain" ? (
                <div className="flex items-center gap-3">
                  <div className="form-group w-1/2">
                    <label htmlFor="registrar">Domain Registrar</label>
                    <select
                      id="registrar"
                      className="form-control"
                      value={domainRegistrar}
                      onChange={handleRegistrarDomainChange}
                      disabled={isViewOpen}
                    >
                      <option value="">Select Registrar</option>
                      <option value="egniolweb">EgniolWeb </option>
                      <option value="godaddy">GoDaddy</option>
                      <option value="hostinger">Hostinger</option>
                      <option value="bigrock">BigRock</option>
                      <option value="namecheap">Namecheap</option>
                      <option value="bluehost">Bluehost</option>
                    </select>
                  </div>
                  <div className="form-group w-1/2">
                    <label htmlFor="expiryDate">Domain Expiry Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="expiryDate"
                      value={expiryDomainDate}
                      onChange={(e) => setExpiryDomainDate(e.target.value)}
                      disabled={isViewOpen}
                    />
                  </div>
                </div>
              ) : null}

              <div className="flex items-center gap-3">
                <div className="form-group w-1/2">
                  <label htmlFor="userId">Hosting Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="hostingName"
                    placeholder="Enter hosting name"
                    value={hostingName}
                    onChange={(e) => setHostingName(e.target.value)}
                    disabled={isViewOpen}
                  />
                </div>

                <div className="form-group w-1/2 flex flex-col pl-1">
                  <label htmlFor="userId">Provided By</label>
                  <div className="flex gap-4 items-center">
                    <label
                      htmlFor="egniolHosting"
                      className="flex items-center gap-1"
                    >
                      <input
                        type="radio"
                        name="providedHostingBy"
                        id="egniolHosting"
                        value="egniolHosting"
                        onChange={handleProvidedByHostingChange}
                        checked={providedHostingBy === "egniolHosting"}
                        disabled={isViewOpen}
                      />
                      Egniol
                    </label>
                    <label
                      htmlFor="clientHosting"
                      className="flex items-center gap-1"
                    >
                      <input
                        type="radio"
                        name="providedHostingBy"
                        id="clientHosting"
                        value="clientHosting"
                        onChange={handleProvidedByHostingChange}
                        checked={providedHostingBy === "clientHosting"}
                        disabled={isViewOpen}
                      />
                      Client
                    </label>
                  </div>
                </div>
              </div>
              {providedHostingBy === "egniolHosting" ? (
                <div className="flex items-center gap-3">
                  <div className="form-group w-1/2">
                    <label htmlFor="registrar">Hosting Registrar</label>
                    <select
                      id="registrar"
                      className="form-control"
                      value={hostingRegistrar}
                      onChange={handleRegistrarHostingChange}
                      disabled={isViewOpen}
                    >
                      <option value="">Select Registrar</option>
                      <option value="egniolweb">EgniolWeb </option>
                      <option value="godaddy">GoDaddy</option>
                      <option value="hostinger">Hostinger</option>
                      <option value="bigrock">BigRock</option>
                      <option value="namecheap">Namecheap</option>
                      <option value="bluehost">Bluehost</option>
                    </select>
                  </div>
                  <div className="form-group w-1/2">
                    <label htmlFor="expiryDate">Hosting Expiry Date</label>
                    <input
                      type="date"
                      className="form-control"
                      id="expiryDate"
                      value={expiryHostingDate}
                      onChange={(e) => setExpiryHostingDate(e.target.value)}
                      disabled={isViewOpen}
                    />
                  </div>
                </div>
              ) : null}

              <div className="d-flex justify-content-center pt-4">
                {isViewOpen ? null : (
                  <button
                    type="button"
                    onClick={isEditOpen ? handleUpdateClient : handleAddClient}
                    className="btn btn-primary mr-4 px-6 text-white"
                  >
                    {isEditOpen ? "Update" : "Add"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          title={"Search Client"}
          visible={searchModal}
          width={"800px"}
          centered
          className="relative !z-[1039]"
          onCancel={() => {
            setSearchModal(false);
            setIsOpen(false);
            setBookingId("");
            setFirmName("");
            setPhone("");
            setEmail("");
            setPaymentStatus("Completed");
            setDomainName("");
            setProvidedDomainBy("");
            setDomainRegistrar("");
            setExpiryDomainDate(null);
            setHostingName("");
            setProvidedHostingBy("");
            setHostingRegistrar("");
            setExpiryHostingDate(null);
            setSearchQuery("");
            setSearchResults([]);
            setClientId("");
          }}
          footer={null}
        >
          <div>
            {" "}
            {/* className=" w-[750px] overflow-x-scroll"  */}
            {/* <form className="search-form"> */}
            <div className="form-group d-flex align-items-center">
              <label htmlFor="searchInput" className="mr-2 mb-0">
                Search:
              </label>
              <input
                type="text"
                className="form-control mr-2"
                id="searchInput"
                placeholder="Enter search query"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
            <div className="mt-4">{/* Gap content */}</div>
            <table className=" table" style={{ tableLayout: "fixed" }}>
              <thead>
                <tr>
                  <th>Booking Id</th>
                  <th>Client Name</th>
                  <th style={{ maxWidth: "200px" }}>Email</th>
                  <th>Phone Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((client) => (
                  <tr key={client.id}>
                    <td>{client.bookingId}</td>
                    <td>{client.firmName}</td>
                    <td style={{ maxWidth: "200px" }}>{client.email}</td>
                    <td>{client.phoneNumber}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => handleSelectClient(client)}
                      >
                        Select
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="mt-4">{/* Gap content */}</div>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-primary w-1/6"
                onClick={() => {
                  setSearchModal(false);
                  setIsOpen(true);
                }}
              >
                Skip
              </button>
            </div>
          </div>
        </Modal>

        <Modal
          title="Send Mail / WhatsApp"
          visible={mailModal}
          width="400px"
          centered
          className="relative !z-[1039]"
          onCancel={() => {
            setMailModal(false);
            setIsOpen(false);
          }}
          footer={null}
        >
          <div className="flex flex-col space-y-4">
            <div className="form-group">
              <label htmlFor="domainName" className="block mb-1">
                Email
              </label>
              <input
                type="text"
                className="form-control w-full px-3 py-2 border rounded"
                id="email"
                placeholder="Enter Email"
                value={emailForMail}
                onChange={(e) => setEmailForMail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="domainName" className="block mb-1">
                Domain Name
              </label>
              <input
                type="text"
                className="form-control w-full px-3 py-2 border rounded"
                id="domainName"
                placeholder="Enter domain name"
                value={domainNameForMail}
                onChange={(e) => setDomainNameForMail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="dateFormat" className="block mb-1">
                Expiry Date
              </label>
              <input
                type="text"
                className="form-control w-full px-3 py-2 border rounded"
                id="expiryDate"
                placeholder="Enter Expiry Date"
                value={dateFormatFroMail}
                onChange={(e) => setDateFormatFroMail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="totalCost" className="block mb-1">
                Total Cost
              </label>
              <input
                type="number"
                className="form-control w-full px-3 py-2 border rounded"
                id="totalCost"
                placeholder="Enter total cost"
                value={totalCostFroMail}
                onChange={(e) => setTotalCostFroMail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="paymentLink" className="block mb-1">
                Payment Link
              </label>
              <input
                type="url"
                className="form-control w-full px-3 py-2 border rounded"
                id="paymentLink"
                placeholder="Enter payment link"
                value={paymentLinkFroMail}
                onChange={(e) => setPaymentLinkFroMail(e.target.value)}
              />
            </div>
            <div className="grid grid-cols-2 gap-4">
              <button
                type="button"
                className="btn btn-primary w-full py-2 mt-4"
                onClick={sendEmail}
              >
                Send Mail
              </button>
              <button
                type="button"
                className="btn btn-primary w-full py-2 mt-4"
                onClick={sendWhatsAppMessage}
              >
                Send Whatsapp
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
