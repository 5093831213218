import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, message } from "antd";
import TableHead from "../../components/resuable/TableHead";
import TableCell from "../../components/resuable/TableCell";
import Table from "../../components/resuable/Table";
import { IoFilterSharp } from "react-icons/io5";
import DateUtils from "../../services/utils/dateUtils";
import { JsonToExcel } from "react-json-to-excel";

export default function BookingTable({
  setIsOpen,
  setIsEditOpen,
  setIsViewOpen,
  clientList,
  setSearch,
  setSearchHostingBy,
  setSearchDomainBy,
  searchDomainBy,
  searchHostingBy,
  search,
  setClientId,
  handleSendEmail,
  handleSendWhatsApp,
}) {
  const columns = [
    {
      id: "firmName",
      name: <TableHead>Firm Name</TableHead>,
      cell: (row) => <TableCell>{row.firmName}</TableCell>,
    },
    {
      id: "hostingName",
      name: <TableHead>Hosting</TableHead>,
      cell: (row) => <TableCell>{row.hostingName}</TableCell>,
    },
    {
      id: "providedDomainBy",
      name: <TableHead>Domain By</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.providedDomainBy === "egniolDomain"
            ? "Egniol"
            : row.providedDomainBy === "clientDomain"
            ? "Client"
            : "-"}
        </TableCell>
      ),
    },
    {
      id: "expiryDomainDate",
      name: <TableHead>Domain Expiry</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.expiryDomainDate
            ? DateUtils.getExDateFormat(row.expiryDomainDate)
            : "-"}
        </TableCell>
      ),
    },
    {
      id: "providedHostingBy",
      name: <TableHead>Hosting By</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.providedHostingBy === "egniolHosting"
            ? "Egniol"
            : row.providedHostingBy === "clientHosting"
            ? "Client"
            : "-"}
        </TableCell>
      ),
    },
    {
      id: "expiryHostingDate",
      name: <TableHead>Hosting Expiry</TableHead>,
      cell: (row) => (
        <TableCell>
          {row.expiryHostingDate
            ? DateUtils.getExDateFormat(row.expiryHostingDate)
            : "-"}
        </TableCell>
      ),
    },
    ...(localStorage.getItem("idDrm") === "1"
      ? [{
          id: "action",
          name: <TableHead>Action</TableHead>,
          cell: (row) => (
            <TableCell>
              <div className="flex gap-5">
                <i
                  className="fas fa-paper-plane"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleSendEmail(row)}
                  title="Send Email"
                ></i>
                <i
                  className="fas fa-edit"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsOpen(true);
                    setIsEditOpen(true);
                    setClientId(row.id);
                  }}
                  title="Edit"
                ></i>
                <i
                  className="fas fa-eye"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsOpen(true);
                    setIsViewOpen(true);
                    setClientId(row.id);
                  }}
                  title="View"
                ></i>
                <i
                  className="fab fa-whatsapp"
                  style={{ cursor: "pointer", color: "#25D366" }}
                  // onClick={() => {
                  //   const clientNumber = row.phoneNumber; // Replace with actual client number field
                  //   const message = "Hello, I wanted to follow up on your booking.";
                  //   const whatsappUrl = `https://wa.me/${clientNumber}?text=${encodeURIComponent(message)}`;
                  //   window.open(whatsappUrl, "_blank");
                  // }}
                  onClick={() => handleSendWhatsApp(row)}
                  title="Send WhatsApp Message"
                ></i>
              </div>
            </TableCell>
          ),
        }]
      : []),
  ];
  
  

  return (
    <div>
      <div className="flex md:flex-row flex-col m-2">
        <div className="form-group w-[300px] mr-10">
          <label
            htmlFor="exampleInputEmail1"
            className="flex gap-3 items-center"
          >
            <IoFilterSharp /> Search
          </label>
          <input
            type="text"
            className="form-control"
            id="category"
            placeholder="Search Category"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="form-group w-[300px] mr-10">
          <label
            htmlFor="exampleInputEmail1"
            className="flex gap-3 items-center"
          >
            <IoFilterSharp /> Domain By
          </label>
          <select
            className="form-control"
            id="category"
            value={searchDomainBy}
            onChange={(e) => setSearchDomainBy(e.target.value)}
          >
            <option value="">---- Select All ----</option>
            <option value="clientDomain">Client</option>
            <option value="egniolDomain">Egniol</option>
          </select>
        </div>
        <div className="form-group w-[300px] mr-10">
          <label
            htmlFor="exampleInputEmail1"
            className="flex gap-3 items-center"
          >
            <IoFilterSharp /> Hosting By
          </label>
          <select
            className="form-control"
            id="category"
            value={searchHostingBy}
            onChange={(e) => setSearchHostingBy(e.target.value)}
          >
            <option value="">---- Select All ----</option>
            <option value="clientHosting">Client</option>
            <option value="egniolHosting">Egniol</option>
          </select>
        </div>
        <div className="form-group w-[100px] mr-10 mt-3 btn-secondary">
          <JsonToExcel
            title="Download "
            data={clientList}
            fileName="Client Data"
            btnClassName="btn-secondary"
          />
        </div>
      </div>

      <div className="card-body">
        <Table columns={columns} rows={clientList} />
      </div>
    </div>
  );
}
