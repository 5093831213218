import React, { useContext, useEffect, useState } from "react";
import { MdDashboard } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { BsGlobe2 } from "react-icons/bs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbLogout2 } from "react-icons/tb";
import companyLogo from "../assets/Egniol_web_Logo_White.png";
import { Modal, message } from "antd";

export default function Menu() {
  const location = useLocation().pathname;
  const navigate = useNavigate();

  const [navResponsive, setNavResponsive] = useState("");
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const LinkActive = (link) => {
    const linkCase = link.toLowerCase();
    const pathCase = location.toLowerCase();
    return pathCase.includes(linkCase);
  };

  const navElements = [];

  const idDrm = localStorage.getItem("idDrm");

  if (idDrm === "1") {
    navElements.push(
      {
        name: "Dashboard",
        path: "/dashboard",
        icon: <MdDashboard />,
      },
      {
        name: "Client Management",
        path: "/client-management",
        icon: <FaUserTie />,
      },
      {
        name: "Domain Entry",
        path: "/domain-entry",
        icon: <BsGlobe2 />,
      }
    );
  } else if (idDrm === "2") {
    navElements.push({
      name: "Domain Entry",
      path: "/domain-entry",
      icon: <BsGlobe2 />,
    });
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    if (windowDimensions.width <= 992) {
      setNavResponsive("flex justify-between");
    } else setNavResponsive("");

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowDimensions.width]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const Hamburger = () => {
    if (!navResponsive) return null;
    return (
      <ul className="navbar-nav">
        <nav
          className={`bg-black navbar-light px-2 py-[0.7em] ${navResponsive}`}
        >
          <button
            onClick={() => navigate("/dashboard")}
            className="flex items-center"
          >
            <img src={companyLogo} alt="logo" className="h-6" />
          </button>
          <ul class="navbar-nav">
            <li className="nav-item pr-1">
              <button onClick={() => handleNav()} type="button">
                <i class="fas fa-bars"></i>
              </button>
            </li>
          </ul>
        </nav>
      </ul>
    );
  };

  const handleLogout = () => {
    localStorage.clear();
    message.success("logged out of the account");
    navigate("/login");
  };

  const handleNav = () => {
    document.body.classList.toggle("sidebar-open");
  };

  const handleCloseNav = () => {
    document.body.classList.remove("sidebar-open");
    document.body.classList.toggle("sidebar-closed");
  };

  return (
    <>
      <Hamburger />
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link
          to="/dashboard"
          className="brand-link flex items-center gap-4 pl-3"
          onClick={handleCloseNav}
        >
          <div>
            <img src={companyLogo} alt="logo" className="h-10" />
          </div>
        </Link>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {navElements.map((item, index) => {
                return (
                  <li className="nav-item" key={index}>
                    {item.path !== "" ? (
                      <Link
                        to={item.path}
                        className={`nav-link flex gap-3 items-center ${
                          LinkActive(item.path) ? "active" : ""
                        }`}
                        onClick={
                          navResponsive
                            ? () => {
                                handleCloseNav();
                              }
                            : () => {}
                        }
                      >
                        {item.icon}
                        <p>{item.name}</p>
                      </Link>
                    ) : (
                      <Link
                        className="nav-link flex gap-3 items-center"
                        // onClick={() => handleBookingModal()}
                      >
                        {item.icon}
                        <p>{item.name}</p>
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
          <div className="mt-3 pb-4 flex justify-between bottom-0 px-2 items-center gap-4 pt-3 border-t">
            <button
              type="button"
              onClick={handleLogout}
              className="text-white text-lg ml-48"
            >
              <TbLogout2 />
            </button>
          </div>
        </div>
      </aside>
    </>
  );
}
